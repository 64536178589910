'use client';

import { Flex, Box, Stack, Heading, useColorModeValue } from '@chakra-ui/react';

import GoogleSignInButton from '~/lib/components/auth/google-signin';

export default function Login() {
  return (
    <Flex
      minH="80vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx="auto" maxW="lg" px={6}>
        <Box
          rounded="lg"
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow="lg"
          p={8}
        >
          <Stack align="center">
            <Heading fontSize="4xl" mb={8}>
              Get started
            </Heading>
          </Stack>
          <Stack spacing={10}>
            <GoogleSignInButton />
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
